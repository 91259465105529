
import { RevRating } from '@ds/components/Rating'
import { mapGetters } from 'vuex'

export default {
  components: {
    RevRating,
  },
  props: {
    score: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    count: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      experiments: 'flags/getExperiments',
    }),
    notZero() {
      return this.score > 0 && this.count > 0
    },
  },
}
