export default {
  startingFrom: {
    id: 'card_starting_from',
    defaultMessage: 'Starting from',
  },
  addToCart: {
    id: 'card_add_to_cart',
    defaultMessage: 'Add to cart',
  },
  tinyAddToCart: {
    id: 'card_tiny_add_to_cart',
    defaultMessage: 'Add',
  },
}
