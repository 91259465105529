
import { mapGetters } from 'vuex'

import { logLanguageMismatch, logger } from '@recommendation/helpers'
import recommendationAPIClient from '@recommendation/recommendationAPIClient'
import { getUserRecommendationId } from '@recommendation/recommendationHelper'

import RecommendedProductsCarousel from '../RecommendedProductsCarousel/RecommendedProductsCarousel.vue'

export default {
  components: {
    RecommendedProductsCarousel,
  },

  props: {
    // This prop is linked to a tracking specificity
    list: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    scope: {
      type: String,
      default: null,
    },
    scopeId: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    personalizedRecommendation: {
      type: Boolean,
      default: false,
    },
    widgetId: {
      type: String,
      default: null,
    },
    withAddToCart: {
      type: Boolean,
      default: false,
    },
    withCrossedPrice: {
      type: Boolean,
      default: false,
    },
    withStartingFrom: {
      type: Boolean,
      default: true,
    },
    withGrade: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: true,
      products: [],
      recommendationWidgetId: '',
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/id',
      locale: 'config/locale',
    }),

    listeners() {
      return this.withAddToCart
        ? { ...this.$listeners, 'add-to-cart': this.addToCart }
        : this.$listeners
    },

    userIdentifiers() {
      if (this.personalizedRecommendation) {
        if (this.userId) {
          return {
            identityRepository: 'customerId',
            identity: this.userId,
          }
        }
        const sessionId = getUserRecommendationId()
        if (sessionId)
          return {
            identityRepository: 'sessionId',
            identity: sessionId,
          }
      }

      return {}
    },
  },

  watch: {
    scopeId(newScopeId, previousScopeId) {
      if (newScopeId !== previousScopeId) {
        this.fetchRecommendations()
      }
    },
  },

  mounted() {
    this.fetchRecommendations()
  },

  methods: {
    async fetchRecommendations() {
      this.isLoading = true

      try {
        const params = {
          limit: this.limit,
          ...this.userIdentifiers,
          scope: this.scope,
          scopeId: this.scopeId,
          category: this.category,
          widgetId: this.widgetId,
        }
        const { products, widgetId } = await recommendationAPIClient(
          (request) => this.$store.dispatch('http/request', request),
        ).getRecommendedProducts(params)

        if (products === undefined || products.length === 0) {
          throw new Error('no recommended products available')
        }

        logLanguageMismatch(products[0]?.link, this.locale, 'recommendation')

        this.products = products
        this.recommendationWidgetId = widgetId

        this.$emit('loaded', this.products)
      } catch (err) {
        this.$emit('error')

        logger.info(err, {
          component: { name: 'RecommendedProductsBlock', props: this.$props },
          routeName: this.$route.name,
        })
      } finally {
        this.isLoading = false
      }
    },

    addToCart({ listingId }) {
      this.$emit('add-to-cart', { listingId })

      if (this.clearable) {
        this.products = this.products.filter((p) => p.listing.id !== listingId)
      }

      if (!this.products.length) {
        this.$emit('cleared')
      }
    },
  },
}
