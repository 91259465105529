import get from 'lodash/get'

function derivedWord(radical, prefix = '', suffix = '') {
  return `${prefix}${radical}${suffix}`
}

function createFormatter(rules, separator = ' ') {
  return (json) =>
    rules
      .map(({ prefix, suffix, getValue }) => {
        const value = getValue(json)

        return value ? derivedWord(value, prefix, suffix) : undefined
      })
      .filter((value) => value !== undefined)
      .join(separator)
}

function getSpecificationValues({ specifications = [] }, fieldName) {
  return specifications.find((spec) => spec?.field === fieldName)?.values ?? []
}

function getSpecificationLabels({ specifications = [] }, fieldName) {
  return getSpecificationValues({ specifications }, fieldName).map(
    ({ label }) => label,
  )
}

const LPListViewAdapter = ({ prefix, suffix, attribute: path }) => ({
  prefix,
  suffix,
  getValue: (obj) => {
    const value = getSpecificationLabels(
      obj,
      path?.replace('list_view.', ''),
    )[0]

    return path?.startsWith('list_view') ? value ?? '' : get(obj, path) ?? ''
  },
})

export function createAttributes(attributesRules, apiData = {}) {
  return attributesRules?.map(({ label, attributes }) => {
    const format = createFormatter(
      attributes.map((rule) => LPListViewAdapter(rule)),
    )

    return { label, attributes: format(apiData) }
  })
}

export function toProductThumb(
  rawData = {},
  {
    attributesRules = [],
    priceFormatter = ({ amount, currency } = {}) =>
      amount && currency && [amount, currency].join(' '),
    position = 1,
    sourceType,
  } = {},
) {
  return {
    id: rawData.listing?.id,
    attributes: createAttributes(attributesRules, rawData),
    brand: rawData.brand,
    category: rawData.category,
    currency: rawData.listing?.price?.currency,
    grade: rawData.listing?.grade?.name,
    isRecommendation: true,
    isOriginalPriceDisplayed: false,
    link: rawData.link,
    sellerId: rawData.listing?.sellerId,
    name: rawData.displayTitle,
    originalPrice: {
      raw: rawData.referencePrice?.amount,
      display: priceFormatter(rawData.referencePrice),
    },
    price: {
      raw: rawData.listing?.price?.amount,
      display: priceFormatter(rawData.listing?.price),
    },
    productId: rawData.id,
    stockRaw: rawData.listing?.stockWarning,
    subTitleModel: rawData.subTitles,
    thumbnail: Array.isArray(rawData.images) ? rawData.images[0] : undefined,
    title: rawData.displayTitle,
    titleModel: rawData.title,
    variant: rawData.listing?.grade?.value,
    warranty: rawData.listing?.warranty,
    position,
    sourceType,

    rawData,
  }
}

export function toTrackingData(
  rawData,
  {
    dealType,
    list,
    position,
    provider,
    source,
    widgetId,
    shippingPrice,
    deepLink,
  } = {},
) {
  return {
    widgetId,
    list,
    product: {
      averageRate: rawData.reviewRating?.average,
      brand: rawData.brand,
      category: rawData.category,
      name: rawData.name,
      id: rawData.id,
      uuid: rawData.link?.params?.uuid,
      price: rawData.listing?.price?.amount,
      currency: rawData.listing?.price?.currency,
      variant: rawData.listing?.grade?.value,
      merchantId: rawData.listing?.sellerId,
      listingId: rawData.listing?.id?.toString(),
      color: getSpecificationLabels(rawData, 'color')[0],
      model: rawData.title,
      shippingPrice,
      list,
      position,
      source,
      dealType,
      provider,
      image: rawData.images?.[0]?.url,
      webUrl: rawData.link?.href,
      mobileDeeplink: `backmarket://${deepLink}`,
      numberTotalReviews: rawData.reviewRating?.count,
    },
  }
}
