
import translations from './PriceWithCrossedPrice.translations'

export default {
  components: {},

  props: {
    price: {
      type: String,
      required: true,
    },
    referencePrice: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    translations: () => translations,
  },
}
