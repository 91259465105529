import { recommendationCollection, recommendationItem } from '@http/endpoints'

import { setRecommendationDebugExtension } from './recommendationHelper'

async function getAllRecommendations(
  bmFetch,
  { limit = 4, scope, scopeId, category, identityRepository, identity },
) {
  const bmRequest = {
    request: recommendationCollection,
    queryParams: {
      limit,
      scope,
      scopeId,
      category,
      identityRepository,
      identity,
    },
  }
  const {
    payload: [firstRecommendation],
  } = await bmFetch(bmRequest)

  setRecommendationDebugExtension(firstRecommendation.recommendationRequestId)

  return firstRecommendation
}

async function getRecommendationById(
  bmFetch,
  { limit = 4, widgetId, identity, identityRepository },
) {
  const bmRequest = {
    request: recommendationItem,
    queryParams: {
      limit,
      identity,
      identityRepository,
    },
    pathParams: { widgetId },
  }
  const { payload } = await bmFetch(bmRequest)

  setRecommendationDebugExtension(payload.recommendationRequestId)

  return payload
}

function getRecommendedProducts(fetch) {
  return async ({
    limit,
    scope,
    scopeId,
    category,
    identityRepository,
    identity,
    widgetId,
  }) => {
    if (widgetId) {
      return getRecommendationById(fetch, {
        limit,
        widgetId,
        identity,
        identityRepository,
      })
    }

    return getAllRecommendations(fetch, {
      limit,
      scope,
      scopeId,
      category,
      identity,
      identityRepository,
    })
  }
}

function getRecommendedBundledProducts(fetch) {
  return async ({ listingId, limit = 1 }) => {
    return getAllRecommendations(fetch, {
      category: 'bundles',
      scope: 'listingBundle',
      scopeId: listingId,
      limit,
    })
  }
}

export default function recommendationAPIClient(fetch) {
  return {
    getRecommendedProducts: getRecommendedProducts(fetch),
    getRecommendedBundledProducts: getRecommendedBundledProducts(fetch),
  }
}
