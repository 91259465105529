
import { RevSkeleton } from '@ds/components/Skeleton'

export default {
  components: {
    RevSkeleton,
  },
  props: {
    label: {
      type: String,
      default: 'Content placeholder',
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
  },
}
