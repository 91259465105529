
import { productHomeDeepLinkBuilder } from '@core/helpers/deepLink'
import { createObserver } from '@core/helpers/vue-Intersection-observer'
import { addToCart } from '@http/endpoints'
import { logger } from '@recommendation/helpers'
import { toTrackingData } from '@recommendation/recommendationAdapter'
import {
  PRODUCT_DEAL_TYPE,
  PRODUCT_PROVIDERS,
  PRODUCT_SOURCE,
  saveProductInfos,
} from '@tracking'
import { trackAddToCart, trackProductClick } from '@tracking/events'

import ProductCard from './ProductCard.vue'
import translations from './ProductCardContainer.translations'

export default {
  components: {
    ProductCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    trackingInformations: {
      type: Object,
      required: true,
    },
    withCrossedPrice: {
      type: Boolean,
      default: false,
    },
    cardWidth: {
      type: String,
      default: 'standard',
    },
    withStartingFrom: {
      type: Boolean,
      default: true,
    },
    withGrade: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoadingAction: false,
      unobserve: null,
    }
  },

  computed: {
    link() {
      return this.product.link
    },

    image() {
      const src = this.product.images?.[0]
      const alt = this.product.displayTitle

      return {
        src,
        alt,
      }
    },

    title() {
      return this.product.displayTitle
    },

    reviewRating() {
      return this.product.reviewRating
    },

    description() {
      return this.product.subTitles.join(' - ')
    },

    grade() {
      return this.withGrade && this.product.listing.grade.name
        ? this.$t(translations.gradeDescription, {
            grade: this.product.listing.grade.name,
          })
        : null
    },

    price() {
      return this.product.listing.price
    },

    referencePrice() {
      return this.withCrossedPrice ? this.product.referencePrice : undefined
    },
    tags() {
      return [this.$t(translations.recommendationTag)]
    },

    listingId() {
      return this.product.listing?.id
    },

    productId() {
      return this.product.id
    },

    trackingData() {
      return toTrackingData(this.product, {
        dealType: PRODUCT_DEAL_TYPE.NORMAL,
        list: this.trackingInformations.list,
        position: this.trackingInformations.position,
        provider: PRODUCT_PROVIDERS.EARLYBIRD,
        source: PRODUCT_SOURCE.RECOMMENDATIONS,
        widgetId: this.trackingInformations.widgetId,
        deepLink: productHomeDeepLinkBuilder({ $route: this.$route }),
      })
    },

    listeners() {
      if (this.$listeners['add-to-cart'])
        return { 'add-to-cart': this.addToCart, click: this.handleClick }

      return { click: this.handleClick }
    },
  },

  destroyed() {
    this.unobserveCard()
  },

  mounted() {
    this.watchCardInViewport()
  },

  methods: {
    handleClick() {
      trackProductClick(this.trackingData)

      saveProductInfos({
        id: this.productId,
        price: this.price?.amount,
        reviewRating: this.reviewRating,
        source: this.trackingInformations.list,
      })

      this.$emit('product-click')
    },

    async addToCart() {
      this.isLoadingAction = true

      try {
        await this.$store.dispatch('http/request', {
          request: addToCart,
          body: {
            listing_id: this.listingId,
          },
        })

        this.$emit('add-to-cart', { success: true, listingId: this.listingId })

        trackAddToCart(this.trackingData)
      } catch (err) {
        this.$emit('add-to-cart', { success: false })

        logger.info(err, {
          component: { name: 'ProductCardContainer', props: this.$props },
          routeName: this.$route.name,
        })
      } finally {
        this.isLoadingAction = false
      }
    },

    unobserveCard() {
      if (this.unobserve) {
        this.unobserve()
      }
    },

    watchCardInViewport() {
      if (this.product.link) {
        this.unobserve = createObserver(this.$el, () => {
          this.$emit('viewport-impression', this.trackingData.product)
        })
      }
    },
  },
}
