export default {
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
  nextSlideText: {
    id: 'cross_sell_slider_next_slide',
    defaultMessage: 'Display next slide',
  },
  prevSlideText: {
    id: 'cross_sell_slider_prev_slide',
    defaultMessage: 'Display previous slide',
  },
  toastClose: {
    id: 'cross_sell_toast_close',
    defaultMessage: 'Close',
  },
  toastSuccessTitle: {
    id: 'cross_sell_toast_title_success',
    defaultMessage: 'Hop',
  },
  toastSuccessMessage: {
    id: 'cross_sell_toast_message_success',
    defaultMessage: 'Product added to the cart.',
  },
  toastErrorTitle: {
    id: 'cross_sell_toast_title_error',
    defaultMessage: 'Oops',
  },
  toastErrorMessage: {
    id: 'cross_sell_toast_message_error',
    defaultMessage: 'Product not added to the cart.',
  },
}
