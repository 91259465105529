export default {
  recommendationTag: {
    id: 'product_card_recommendation_tag',
    defaultMessage: 'Good deal',
  },
  gradeDescription: {
    id: 'product_card_condition',
    defaultMessage: 'Condition: {grade}',
  },
  warrantyDescription: {
    id: 'product_card_warranty',
    defaultMessage: 'Warranty: {warranty}',
  },
}
