import isEmpty from 'lodash/isEmpty'

import { ROUTES } from '@router'

export const homeDeepLinkBuilder = () => {
  return 'start/home'
}

export const productHomeDeepLinkBuilder = ({ $route }) => {
  const { params, hash } = $route
  const { uuid } = params
  const specialOfferType = isEmpty(hash) ? '0' : hash.replace('#l=', '')

  return `product/${uuid}?specialOfferType=${specialOfferType}`
}

export const landingProductDeepLinkBuilder = ({ $store }) => {
  const { landingId } = $store

  return landingId ? `landing/${landingId}` : homeDeepLinkBuilder()
}

export const DEEP_LINK_BUILDER_BY_ROUTE = {
  [ROUTES.HOME]: homeDeepLinkBuilder,
  [ROUTES.PRODUCT.HOME]: productHomeDeepLinkBuilder,
  [ROUTES.LANDING.PRODUCT]: landingProductDeepLinkBuilder,
}

export const getDeepLinkByRoute = ({ $route, $store }) => {
  const { name } = $route
  const deepLinkBuilder =
    typeof DEEP_LINK_BUILDER_BY_ROUTE[name] === 'function'
      ? DEEP_LINK_BUILDER_BY_ROUTE[name]
      : homeDeepLinkBuilder

  return deepLinkBuilder({ $route, $store })
}
