import { isBrowser } from '@core/helpers'

export const getUserRecommendationId = () => {
  return localStorage?.getItem('attraqtsessionid')
}

export const setRecommendationDebugExtension = (id) => {
  if (!isBrowser()) {
    return
  }
  if (!id) {
    return
  }

  window.ebRecoIds = [...new Set(window.ebRecoIds).add(id)]
}
