
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevToast } from '@ds/components/Toast'
import isEmpty from 'lodash/isEmpty'

import { TOAST_CROSS_SELL } from '@config/constants/toasts'
import { logger } from '@recommendation/helpers'
import { trackProductImpressionBatched } from '@tracking/events'

import ProductCardContainer from '../ProductCards/ProductCardContainer.vue'

import CarouselSkeleton from './CarouselSkeleton.vue'
import translations from './RecommendedProductsCarousel.translations'

const TOAST_VARIANT = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export default {
  components: {
    RevCardCarousel,
    CarouselSkeleton,
    RevToast,
    ProductCardContainer,
  },

  props: {
    products: {
      type: Array,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    widgetId: {
      type: String,
      required: true,
    },

    list: {
      type: String,
      required: true,
    },

    withAddToCart: {
      type: Boolean,
      default: false,
    },

    withCrossedPrice: {
      type: Boolean,
      default: false,
    },

    withStartingFrom: {
      type: Boolean,
      default: true,
    },

    withGrade: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      productImpressions: [],
      toast: {
        icon: '',
        isOpened: false,
        message: '',
        title: '',
        variant: '',
      },
      trackingInterval: null,
    }
  },

  computed: {
    TOAST_CROSS_SELL: () => TOAST_CROSS_SELL,

    translations: () => translations,

    toastSuccess() {
      return {
        title: this.$t(translations.toastSuccessTitle),
        message: this.$t(translations.toastSuccessMessage),
        icon: this.$static('/img/toast/rabbit.svg'),
        variant: TOAST_VARIANT.SUCCESS,
      }
    },

    toastError() {
      return {
        title: this.$t(translations.toastErrorTitle),
        message: this.$t(translations.toastErrorMessage),
        icon: this.$static('/img/toast/storm.svg'),
        variant: TOAST_VARIANT.ERROR,
      }
    },

    listeners() {
      return this.withAddToCart
        ? { ...this.$listeners, 'add-to-cart': this.addToCart }
        : this.$listeners
    },

    desktopCardWidth() {
      return 'standard'
    },

    mobileCardWidth() {
      if (this.withAddToCart) {
        return 'standard'
      }

      return 'narrow'
    },
  },

  mounted() {
    this.trackingInterval = setInterval(() => {
      if (!isEmpty(this.productImpressions)) {
        try {
          trackProductImpressionBatched(this.productImpressions)
        } catch (err) {
          logger.info(err, {
            component: {
              name: 'RecommendedProductsCarousel',
              props: this.$props,
            },
            routeName: this.$route.name,
          })
        } finally {
          this.productImpressions = []
        }
      }
    }, this.$config.BATCH_TRACKING_INTERVAL)
  },

  beforeDestroy() {
    clearInterval(this.trackingInterval)
  },

  methods: {
    saveImpression(productTracking) {
      this.productImpressions.push(productTracking)
    },

    addToCart({ success, listingId }) {
      if (success) {
        this.toast = this.toastSuccess

        this.$emit('add-to-cart', { listingId })
      } else {
        this.toast = this.toastError
      }

      this.toast.isOpened = true
    },

    closeToast() {
      this.toast = { ...this.toast, isOpened: false }
    },
  },
}
