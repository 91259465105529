
import { RevButton } from '@ds/components/Button'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'
import { IconShoppingBag } from '@ds/icons/IconShoppingBag'
import { mapGetters } from 'vuex'

import ProductCardRatings from '@landing/components/ProductCardRatings/ProductCardRatings.vue'
import { COUNTRIES_WITHOUT_ORIGINAL_PRICE as COUNTRIES_WITHOUT_REFERENCE_PRICE } from '@product/pages/product.constants'

import PriceWithCrossedPrice from '../PriceWithCrossedPrice/PriceWithCrossedPrice.vue'

import translations from './ProductCard.translations'

export default {
  components: {
    RevButton,
    RevButtonBase,
    RevIllustration,
    RevCard,
    RevTag,
    PriceWithCrossedPrice,
    ProductCardRatings,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    imageAlt: {
      type: String,
      default: 'alt text is missing',
    },
    imageSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    grade: {
      type: String,
      default: '',
    },
    price: {
      type: Object,
      required: true,
    },
    referencePrice: {
      type: Object,
      default: undefined,
    },
    reviewRating: {
      type: Object,
      required: false,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    cardWidth: {
      type: String,
      default: 'standard',
    },
    withStartingFrom: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isFocused: false,
    }
  },

  computed: {
    ...mapGetters({
      country: 'config/country',
    }),

    translations: () => translations,

    IconShoppingBag: () => IconShoppingBag,

    withAddToCart() {
      return this.$listeners['add-to-cart'] !== undefined
    },

    isNarrow() {
      return this.cardWidth === 'narrow'
    },
    refPrice() {
      if (
        !this.referencePrice ||
        COUNTRIES_WITHOUT_REFERENCE_PRICE.includes(this.country)
      ) {
        return null
      }

      return this.$t.price(this.referencePrice)
    },
  },
}
